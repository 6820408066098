 /* © 2020 Will Burklund. All rights reserved. */
.App {
  text-align: center;
}

#header {
  align-items: center;
  height: 4rem;
  margin: 0rem;
  box-shadow: 0 .2rem 1rem rgba(0,0,0,0.8)
}

.cornerLogo {
  margin-left: .3rem;
  padding-left: .8rem;
}

.pageContainer {
  width: 100%;
  margin: auto;
}

.normalFontWeight {
  font-weight: 400 !important;
}

#footer {
  align-items: center;
  justify-content: center;
  margin-top: 0;
  height: 4rem;
}