 /* © 2020 Will Burklund. All rights reserved. */
@keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-5rem); }
    100% {
      opacity: 1;
      transform: translate(0); } }
  
  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(5rem); }
    100% {
      opacity: 1;
      transform: translate(0); } }

  @keyframes shrink {
    from {max-height: 100vh;}
    to {max-height: 20vh;}
  }
  @keyframes glow {
    40% {
      text-shadow: 0 0 8px #fff;
    }
  }

.hero {
    width: 100vw;
    height: 75vh;
    position: relative;
}

.hero__header {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.8);
    animation: glow 4s linear infinite;
    text-align: center;
    color: #fff;
}

.hero__header__main {
    margin: auto;
}

.hero__header__sub {
    margin: auto;
}

.blurb {
  display: inline-block;
  width: 30vw;
}

#strengthsCardGroup {
  justify-content: space-around;
  max-width: 40vw;
  margin: auto;
}

#strengthsCardGroup::after {
  display: none;
}

.strength-card {
  box-shadow: none !important;
  padding: 3rem !important;
}

.strength-card__content {
  border: none !important;
  min-height: 150px;
}